import { fetchData } from '@/libs/apiHelper'
import { images} from "@/config";
import {searchItems} from "@/libs/helper";

const design = {
    modules: {
        namespaced: true
    },
    state: {
        designs: [],
        folders: [],
        mostOrderedIds: [],
        newsIds: [],
        searchString: '',
        categoryId: 'all',
        resultSize: 0,
        scrollY: 0,

        editMode: false,
        showFeed: false,
    },
    mutations: {
        setDesignState (state, payload) {
            for (let key in payload)
                state[key] = payload[key];
        },
    },
    getters: {
        hasDesigns(state) {
            return state.designs.length > 0;
        },
        filteredDesigns(state) {
            if(state.searchString) {
                // add 10 to get a result bigger than resultSize so we know if show more button should be visible
                return searchItems(state.searchString, state.designs);
            }

            let filtered;
            if (state.categoryId === 'all') {
                filtered = state.designs;
            } else if (state.categoryId === 'favorites') {
                filtered = state.designs.filter(item => item.favorite && !item.hidden);
            } else if (state.categoryId === 'news') {
                let newsIds = state.newsIds;
                filtered = state.designs.filter(item => newsIds.includes(item.id))
            } else if(state.categoryId === 'most_ordered') {
                let mostOrderedIds = state.mostOrderedIds;
                filtered = state.designs.filter(item => mostOrderedIds.includes(item.id))
            } else {
                filtered = state.designs.filter(item => {
                    return item.categories.find(cat => cat.id == state.categoryId)
                });
            }
            return filtered;
        },

        currentImage(state, getters) {
            return images.find((item) => item.id === getters.getImageId) || images[0];
        },

        noDesignPath(state, getters, rootState) {
            return `${rootState.sourceUrl}/images/nodesign.png`;
        },
    },
    actions: {
        async getDesign(ctx, id) {
            if(!ctx.state.designs.length)
                await ctx.dispatch('getDesigns');

            return ctx.state.designs.find((item)=>item.id == id);
        },
        async getDesigns(ctx) {
            if(ctx.state.designs.length)
                return;

            const result = await fetchData(ctx, `${ctx.rootState.url}/designs/${ctx.rootState.agency_key}/all`);
            ctx.commit('setDesignState', {
                designs: result.items,
                folders: result.folders,
                mostOrderedIds: result.most_ordered_ids,
                newsIds: result.news_ids,
            })
        },
    },
}

export default design