<template>
    <div class="icon-input-wrapper">
        <font-awesome-icon class="icon fa-fw" height="1em" :icon="['far', 'tags']"/>
        <select class="categories input-light" :value="value" @change="$emit('change', $event.target.value)">
            <option value="all">{{ translate('inspo.all_categories') }}</option>
            <template v-for="folder in folders" :key="folder.id">
                <option v-if="folder.id === 'sep'" :value="folder.id" disabled>---</option>
                <option v-else :value="folder.id">{{folder.name}}</option>
            </template>
        </select>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { translate } from "@/libs/helper";

export default {
    props: {
        value: String
    },
    emits: ['change'],
    setup() {
        const store = useStore();

        const folders = computed(() => {
            if(!store.state.design.mostOrderedIds.length)
                return store.state.design.folders.filter(item => item.id !== 'most_ordered')
            return store.state.design.folders;
        })        

        return {
            translate,
            folders,
        }
    }
}
</script>

<style scoped >

.icon-input-wrapper{
    width: 100%;
}

.categories{
    width: 100%;
    height: 100%;
    margin-left: .2em;
    padding: .47em .5em .46em 2.1em;
}
.icon {
    color: var(--secondary-text);
}

</style>