<template>
    <div class="icon-input-wrapper">
        <font-awesome-icon class="icon fa-fw" height="1em" :icon="['far', 'search']"/>
        <input ref="txtSearch"
               class="input-light"
               type="text"
               :placeholder="translate('inspo.search_design_archive')"
               :value="value"
               @input="$emit('change', $event.target.value)"
        >
        <button v-if="value" class="clear-icon-wrapper" @click="$emit('change','')">
            <font-awesome-icon class="icon" color="white" height="1em" :icon="['far', 'times']"/>
        </button>
    </div>
</template>

<script>
import { ref, nextTick, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { translate } from "@/libs/helper";

export default {
    props: {
        value: String
    },
    emits: ['change'],
    setup() {
        const route = useRoute()
        const txtSearch = ref(null)

        onMounted(() => {
            if(!route.query.categoryId) {
                nextTick(() => { txtSearch?.value.focus() })
            }
        })

        return {
            translate,
            txtSearch,
        }
    },
}
</script>

<style scoped >

.icon-input-wrapper{
    width: 100%;
}

input{
    width: 100%;
    height: 100%;
    margin-right: .2em;
    padding: .47em .5em .46em 2.1em;
}

input::placeholder {
    color: var(--secondary-text);
}

.icon {
    color: var(--secondary-text);
}

.clear-icon-wrapper {
    position: absolute;
    width: 2em;
    height: 2em;
    top: .25em;
    right: .5em;
}
.clear-icon-wrapper .icon {
    top: 0.5em;
}


</style>