import { fetchData } from '@/libs/apiHelper'

const translations = {
    modules: {
        namespaced: true
    },
    state: {
        translations: {},
    },
    mutations: {
        setTranslations(state, translations) {
            state.translations = translations;
        },
    },
    actions: {
        getTranslations(ctx){
            fetchData(ctx, `${ctx.rootState.url}/translations`)
                .then(r => {
                    ctx.commit("setTranslations", r)
                })
                .catch(() => ctx.commit("setTranslations", []));
        }
    },
    getters: {
        translate(state) {
            return key => {
                return state.translations[key] || key
            }
        },
    }
}

export default translations