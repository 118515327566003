<template>
    <div class="btn icon-input-wrapper" :class="{
            'hide-small-flex' : roundOnSmall,
            'hide-medium-flex' : roundOnMedium,
            'primary-btn' : !secondary,
            'secondary-btn' : secondary,
            'smallerText' : smallerText,
        }"
        @click="btnClicked"
        :style="{opacity:disabled ? 0.4 : 1 }"
    >
        <font-awesome-icon v-if="!!icon" class="icon fa-fw" height="1em" :icon="['far', icon]" />
        <button class="input" :class="{ 'has-icon':!!icon  }" >{{ label }}</button>
    </div>
    <div class="btn icon-only-wrapper" :class="{
            'show-small-flex' : roundOnSmall && !roundOnMedium,
            'show-medium-flex' : roundOnMedium,
            'd-none': !roundOnSmall && !roundOnMedium,
            'primary-btn' : !secondary,
            'secondary-btn' : secondary,
            'smallerText' : smallerText,
         }"
         @click="btnClicked"
         :style="{opacity:disabled ? 0.4 : 1 }"
    >
         <font-awesome-icon v-if="!!icon" class="icon fa-fw" height="16" :icon="['far', icon]"/>
    </div>
</template>

<script>
export default {
    props: {
        icon: String,
        label: String,
        disabled: Boolean,
        roundOnSmall: Boolean,
        roundOnMedium: Boolean,
        secondary: Boolean,
        smallerText: Boolean,
        clickFunction: Function,
    },
    setup(props) {
        const btnClicked = () => {
            if(props.disabled)
                return;

            if(props.clickFunction)
                props.clickFunction();
        };

        return {
            btnClicked,
        }
    }
}
</script>

<style scoped>
    .icon-only-wrapper {
        height: 2em;
        width: 2em;
        padding: 0;
        margin: .15em;
        border-radius: 100px;
    }

    .btn.smallerText {
        font-size: 85%;
    }
</style>