<template>
    <div class="app-layout" :class="appClassNames">
        <router-view :key="route.fullPath"></router-view>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { onMounted  } from '@vue/runtime-core';
import {default_typograhy} from "@/config";
import {computed, nextTick, onBeforeUnmount, ref} from "vue";

export default {
    props: {
        dataSet: Object,
    },
    setup(props) {
        const store = useStore();
        const route = useRoute()

        const additionalBottomPadding = ref(false);

        store.commit('setState', {
            lang: props.dataSet.value.lang,
            agency_key: props.dataSet.value.agency_key,
            use_context: props.dataSet.value.use_context,
            project: props.dataSet.value.project,
            can_set_preferred_design: props.dataSet.value.can_set_preferred_design,
            edit_disabled: props.dataSet.value.edit_disabled,
            preferred_design_ids: props.dataSet.value.preferred_design_ids,
        });

        store.commit('setTypographyId', default_typograhy[props.dataSet.value.lang] || 0)

        if(props.dataSet.value.inquiry_key)
            store.commit('setInquiryKey', props.dataSet.value.inquiry_key);

        store.dispatch('setUrl');
        store.dispatch('getTranslations');

        dispatchEvent(new CustomEvent('inspoready', {
            bubbles: true,
            composed: true,
        }))

        const onResize = () => {
            const rootElement = document.getElementById('im4-inspiration-embed')
            const width = rootElement.getBoundingClientRect().width;

            let state = { moduleWidth: width }
            if (width >= 770 && width < 980)
                state.size = 'medium';
            else if (width >= 980)
                state.size = 'large';
            else
                state.size = 'small';

            state.xsmall = width < 450;
            state.xbig = width > 1400;
            store.commit("setState",  state);
        }

        const onSubjectImageChange = (event) => {
            store.commit('setSubjectImage', event.detail);

            if(!event.detail && store.getters.getImageId === 3) {
                store.commit('setImageId', 0)
            } else if(event.detail && [1,2].includes(store.getters.getImageId)) {
                store.commit('setImageId', 3)
            }
        }

        const onPreferredDesignsChange = (event) => {
            store.commit('setState', {
                preferred_design_ids : event.detail.split(",")
            })
        }

        const appClassNames = computed(()=>{
            let classNames = store.getters.contextClassNames;
            if(additionalBottomPadding.value)
                classNames += " extra-bottom-padding";
            return classNames;
        });

        onMounted(() => {
            // check domain where module is included
            const domain = document.domain.replace("www.","").toLowerCase();
            if(['inmemory.se','inmemory.no'].includes(domain)){
                // look for floating element with negativ margin top
                if(document.querySelector('.fluid-container.-mt-44')) {
                    additionalBottomPadding.value = true;
                }
            }

            onResize()
            window.addEventListener("resize", onResize);

            nextTick(()=>{
                if(props.dataSet.value.selected_colors) {
                    let shadowRoot = document.getElementById('im4-inspiration-embed').shadowRoot
                    let shadowRootElement = shadowRoot.getElementById("im4-inspiration-embed");
                    let styles = document.createElement('style');

                    if (props.dataSet.value.selected_colors.primary)
                        styles.innerHTML += '* { --primary:' + props.dataSet.value.selected_colors.primary + ';}'
                    if (props.dataSet.value.selected_colors.primary_text)
                        styles.innerHTML += '* { --primary-text:' + props.dataSet.value.selected_colors.primary_text + ';}'
                    if (props.dataSet.value.selected_colors.secondary)
                        styles.innerHTML += '* { --secondary:' + props.dataSet.value.selected_colors.secondary + ';}'
                    if (props.dataSet.value.selected_colors.secondary_text)
                        styles.innerHTML += '* { --secondary-text:' + props.dataSet.value.selected_colors.secondary_text + ';}'

                    shadowRootElement.appendChild(styles);
                }
            })

            if(props.dataSet.value.project) {
                window.addEventListener('projectsubjectimage', onSubjectImageChange);
                window.addEventListener('projectpreferreddesigns', onPreferredDesignsChange);
            }

            dispatchEvent(new CustomEvent('insporoute', {
                bubbles: true,
                composed: true,
                detail: window.location.hash
            }))
        })

        onBeforeUnmount(()=>{
            window.removeEventListener("resize", onResize);
            if(props.dataSet.value.project) {
                window.removeEventListener('projectsubjectimage', onSubjectImageChange);
                window.removeEventListener('projectpreferreddesigns', onPreferredDesignsChange);
            }
        });

        return {
            store,
            route,
            appClassNames,
        }
    },
}
</script>

<style scoped>

.app-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    max-width: 100%;
    min-height: 50vh;
    font-size: 16px;
}

.app-layout.projectshare{
    padding: 0;
}

.app-layout.extra-bottom-padding {
    padding-bottom: 5rem;
}

.mq-medium.app-layout.extra-bottom-padding,
.mq-large.app-layout.extra-bottom-padding
{
    padding-bottom: 1.5rem;
}



</style>

<style src="@/assets/main.css"/>
