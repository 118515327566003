<template>
    <section class="topbar card" >
        <div class="topbar-start">
            <BtnNavigation v-if="backFunction"
                           icon="chevron-left"
                           class="btn-back"
                           :label="backLabel"
                           :clickFunction="backFunction"
                           backNavigation
                           secondaryText
            />
            <p v-if="title" class="title">{{ title }}</p>
        </div>
        <div class="topbar-end">
            <slot name="topbar-end"></slot>
        </div>
    </section>
</template>

<script>

import BtnNavigation from '../components/BtnNavigation.ce.vue';

export default {
    components: {
        BtnNavigation,
    },
    props: {
        title: String,
        backFunction: [String,Function],
        backLabel: String,
    },
    setup() {
    },
}
</script>

<style scoped>

 .topbar {
     padding: 0 .9em;
 }

 .topbar-start,
 .topbar-end {
    display: flex;
    flex-direction: row;
    align-items: center;
 }

 .topbar-end {
     gap: .25em;
 }

 .title{
     color: var(--secondary-text);
 }

 .mq-large .btn-back {
     margin-left: .2em;
 }
 .mq-large .title {
     display: block;
     font-size: 18px;
     margin-left: .5em;
 }

</style>