import {defineCustomElement as VueDefineCustomElement, h, createApp, getCurrentInstance, ref} from 'vue'
import router from '../router'
import store from '../store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(far)

const nearestElement = (el) => {
    while (el?.nodeType !== 1 /* ELEMENT */) el = el.parentElement
    return el
}

const getDataSet = () => {
    const id = document.getElementById("im4-inspiration")

    let lang = 'gb'
    if(id) {
        lang = id.dataset.lang;
        if(lang === 'da')
            lang = 'dk';
        else if(lang === 'en')
            lang = 'gb';
        else if(lang === 'sv')
            lang = 'se';
        else if(lang === 'no')
            lang = 'nb';
    }

    let selectedColors = {}
    if(id) {
        if(id.dataset.primary)
            selectedColors.primary = id.dataset.primary;
        if(id.dataset.primary_text)
            selectedColors.primary_text = id.dataset.primary_text;
        if(id.dataset.secondary)
            selectedColors.secondary = id.dataset.secondary;
        if(id.dataset.secondary_text)
            selectedColors.secondary_text = id.dataset.secondary_text;
    }

    return {
        lang,
        agency_key: id && id.dataset.agency_key ? id.dataset.agency_key : '',
        edit_disabled: id && id.dataset.edit_disabled ? id.dataset.edit_disabled : '',
        selected_colors: selectedColors,
        use_context: id && id.dataset.context ? id.dataset.context : null,
        project: id && id.dataset.project ? JSON.parse(id.dataset.project) : null,
        inquiry_key: id && id.dataset.inquiry ? id.dataset.inquiry : null,
        can_set_preferred_design: id && id.dataset.can_preferred_designs ? id.dataset.can_preferred_designs : null,
        preferred_design_ids: id && id.dataset.preferred_design_ids ? id.dataset.preferred_design_ids.split(",") : [],
    }
}

export const defineCustomElement = (component) =>
    VueDefineCustomElement({
        // render: () => h(component),
        props: component.props,
        styles: component.styles,
        setup(props) {
            const app = createApp()

            app.component('font-awesome-icon', FontAwesomeIcon)

            // Css from child components
            app.mixin({
                mounted() {
                    const insertStyles = (styles) => {
                        if (styles?.length) {
                            this.__style = document.createElement('style')
                            this.__style.innerText = styles.join().replace(/\n/g, '')
                            nearestElement(this.$el).prepend(this.__style)
                        }
                    }

                    insertStyles(this.$?.type.styles)
                    if (this.$options.components) {
                        for (const comp of Object.values(this.$options.components)) {
                            insertStyles(comp.styles)
                        }
                    }

                    const webComponent = document.body.querySelector('inspiration-embed');
                    webComponent.setAttribute('id', 'im4-inspiration-embed')

                    const fontImport = document.createElement('link')
                    this.setAttributes(fontImport, [
                        {
                            name: 'href',
                            value: 'https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600'
                        },
                        { name: 'rel', value: 'stylesheet' },
                        { name: 'type', value: 'text/css' },
                        { name: 'id', value: 'fonts' }
                    ])

                    const elementExists = document.getElementById("fonts");
                    if(!elementExists)
                        document.head.appendChild(fontImport)
                },
                unmounted() {
                    this.__style?.remove()
                },
                methods: {
                    setAttributes (elem, attrs) {
                        attrs.forEach(attr => {
                            elem.setAttribute(attr.name, attr.value)
                        })
                    }
                },
            })

            // install plugins
            app.use(router)
            app.use(store)

            const inst = getCurrentInstance()

            const dataSet = ref(getDataSet());

            Object.assign(inst.appContext, app._context)
            Object.assign(inst.provides, app._context.provides)
            Object.assign(inst.props, { dataSet });

            return () => h(component, props)
        },
    })