import { createStore } from 'vuex'
import {defaultUrl, rootUrls} from "@/config";
import design from './design'
import inquiry from './inquiry'
import inspiration from './inspiration'
import translations from './translations'
import {fetchData} from "@/libs/apiHelper";

const store = createStore({
    modules: {
        translations,
        design,
        inspiration,
        inquiry,
    },
    state: {
        lang: 'gb',
        url: defaultUrl,
        sourceUrl: '/',
        agency_key: '',
        use_context: null,
        project: null,
        edit_disabled: '',
        errorOccured: false,
        moduleWidth: 770,
        can_set_preferred_design: false,
        preferred_design_ids: [],
        size: 'medium',
        xsmall: false,
        xbig: false,
        settings: [],
        fonts: [],
    },
    mutations: {
        setState (state, payload) {
            for (let key in payload)
                state[key] = payload[key];
        },
        setErrorOccured(state, bool) {
            state.errorOccured = bool;
        },
        setSubjectImage(state, image) {
            state.project.subject_image = image;
        },
        setFonts(state, fonts) {
            state.fonts = fonts;
        },
    },
    getters: {
        isProjectshare(state) {
            return state.use_context === 'projectshare';
        },
        showSetPreferredDesign(state) {
            return state.use_context === 'projectshare' && state.can_set_preferred_design;
        },
        contextClassNames(state, getters) {
            const classNames = [];
            if(getters.isProjectshare)
                classNames.push('projectshare');
            if(state.size === 'small')
                classNames.push('mq-small');
            if(state.size === 'medium')
                classNames.push('mq-medium');
            if(state.size === 'large') {
                classNames.push('mq-large');

                if (state.moduleWidth < 1100) {
                    classNames.push('mq-medium-large')
                }
            }

            return classNames.join(" ");
        },
    },
    actions: {
        setUrl(ctx) {
            ctx.commit("setState", {
                url: rootUrls[ctx.state.lang],
                sourceUrl: ['stage','stage2'].includes(ctx.state.lang)
                    ? 'https://stage-inspiration.memosoft.net'
                    : 'https://inspiration.memosoft.net',
            })
        },
        getSettings(ctx){
            return fetchData(ctx, `${ctx.state.url}/inspiration/${ctx.state.agency_key}/settings`)
                .then(r => {
                    ctx.commit("setState", { settings : r})
                })
                .catch(() => ctx.commit("setState", {settings:[]}));
        },
        async getFonts(ctx){
            const result = await fetchData(ctx, `${ctx.rootState.url}/fonts/${ctx.rootState.agency_key}/all`);
            ctx.commit("setFonts", result);
        },
    },
})

export default store