import { postData, fetchData } from '@/libs/apiHelper'
import {default_inquiry} from "@/config";

const inquiry = {
    modules: {
        namespaced: true
    },
    state: {
        inquiryKey: null,
        inquiry: default_inquiry,
    },
    mutations: {
        setInquiryData(state, payload) {
            for (let key in payload)
                state.inquiry[key] = payload[key];
        },
        setInquiryKey(state, inquiryKey) {
            state.inquiryKey = inquiryKey
            sessionStorage.setItem('inspo_key', inquiryKey);
        },
        setInquiry(state, inquiry) {
            state.inquiry = inquiry
        },
        setEffectId(state, effectId) {
            state.inquiry.json.effect = effectId;
        },
        setPositionId(state, positionId) {
            state.inquiry.json.placement = positionId;
        },
        setTypographyId(state, typographyId) {
            state.inquiry.json.typography = typographyId;
        },
        setImageId(state, imageId) {
            state.inquiry.json.show_image = imageId;
        },
        setImagePosition(state, position) {
            state.inquiry.json.image_position = position;
        },
        setDeceasedName(state, name) {
            state.inquiry.deceased = name;
        },
        setNameFontId(state, fontId) {
            state.inquiry.json.fontstyle_name = fontId;
        },
        setTextFontId(state, fontId) {
            state.inquiry.json.fontstyle_text = fontId;
        },
        setDesignId(state, designId) {
            state.inquiry.json.design_id = designId;
        },
        setName(state, name) {
            state.inquiry.name = name;
        },
        setEmail(state, email) {
            state.inquiry.email = email;
        },
        setMessage(state, message) {
            state.inquiry.message = message;
        },
    },
    getters: {
        getEffectId(state) {
            return state.inquiry.json.effect || 0;
        },
        getPositionId(state) {
            return state.inquiry.json.placement || 0;
        },
        getTypographyId(state) {
            return state.inquiry.json.typography || 0;
        },
        getImageId(state) {
            return state.inquiry.json.show_image || 0;
        },
        getImagePosition(state) {
            return state.inquiry.json.image_position || 0;
        },
        getDeceasedName(state) {
            return state.inquiry.deceased || '';
        },
        getNameFontId(state) {
            return state.inquiry.json.fontstyle_name;
        },
        getTextFontId(state) {
            return state.inquiry.json.fontstyle_text;
        },
        getName(state) {
            return state.inquiry.name;
        },
        getEmail(state) {
            return state.inquiry.email;
        },
        getMessage(state) {
            return state.inquiry.message;
        },
    },
    actions: {
        async getInquiry(ctx, key){
            if(!key) {
                ctx.commit("setInquiry", default_inquiry);
            } else {
                fetchData(ctx, `${ctx.rootState.url}/inquiries/${key}`)
                    .then(r => {
                        ctx.commit("setInquiry", r)
                        ctx.commit("setInquiryKey", key);
                    })
                    .catch(() => {
                        ctx.commit("setInquiry", default_inquiry);
                    });
            }
        },
        async submitInquiry(ctx, payload) {
            const params = [];
            if(payload.shareOnly)
                params.push("share_only=true");
            if(ctx.state.inquiryKey)
                params.push("inquiry_key=" + ctx.state.inquiryKey);
            if(payload.designId)
                ctx.commit('setDesignId', payload.designId);

            const component = payload.designId ? 'designs' : 'inspiration';
            const addOn = params.length ? "?"+ params.join("&") : '';

            const url = `${ctx.rootState.url}/${component}/${ctx.rootState.agency_key}/${payload.designId || payload.inspirationId}/contact/${addOn}`;
            const result = await postData(url, ctx.state.inquiry)
                .then(r => { return r })
                .catch((r) => console.log("Post error", r));

            if(payload.designId) {
                if (ctx.state.inquiryKey !== result.inquiry_key)
                    ctx.commit('setInquiryKey', result.inquiry_key);

                if (ctx.getters.isProjectshare) {
                    dispatchEvent(new CustomEvent('inspoinquiry', {
                        bubbles: true,
                        composed: true,
                        detail: result.inquiry_key
                    }))
                }
            }
        },
    },
}
export default inquiry