export const defaultUrl = "https://portal.memographics.com/api/modules";

export const rootUrls = {
    nb: 'https://im4.inmemory.no/api/modules',
    gb: defaultUrl,
    se: 'https://im4.inmemory.se/api/modules',
    dk: 'https://im4.inmemory.dk/api/modules',
    de: 'https://portal.memographics.de/api/modules',
    stage: 'https://no-stage-inmemory4.gc.no/api/modules',
    stage2: 'https://stage2-inmemory4.gc.no/api/modules'
}

export const typographies = [
    { id: 0, icon: 'left-justified.svg', preview: 'Left-Justified.png' },
    { id: 1, icon: 'left-align.svg', preview: 'Left-Align.png' },
    { id: 2, icon: 'center-align.svg', preview: 'Center-Align.png' },
    { id: 3, icon: 'blocktext.svg', preview: 'BlockText.png' },
    { id: 4, icon: 'left-right-align.svg', preview: 'Left-Right-Align.png' },
    { id: 5, icon: 'columns.svg', preview: 'Columns.png' }
]

export const default_typograhy = {
    nb: 0,
    gb: 0,
    se: 3,
    dk: 5,
    de: 0,
    stage: 0,
    stage2: 0,
}

export const effects = [
    { id: 0, name:'none'},
    { id: 1, name:'full'},
    { id: 2, name:'bottom'},
    { id: 3, name:'top'},
    { id: 4, name:'center'},
]

export const positions = [
    { id: 0, name: 'space-between' },
    { id: 1, name: 'flex-start' },
    { id: 2, name: 'flex-end' },
    { id: 3, name: 'center' },
]

export const images = [
    { id: 0, icon:'image-slash', file: null, dummy_name:'inspo.content_name'},
    { id: 1, icon:'person-simple', file: 'eldre_mann.jpg', dummy_name:'inspo.content_name'},
    { id: 2, icon:'person-dress-simple', file: 'senior_woman.jpg', dummy_name: 'inspo.content_name_female' },
    { id: 3, icon:'image', file: 'project' },
]

export const image_positions = [
    { id: 0, icon:'arrow-up' },
    { id: 1, icon:'arrow-down' },
]

export const default_inquiry = {
    default: true,
    name: '',
    email: '',
    deceased: '',
    message:'',
    json: {
        show_image: 0,
        placement: 0,
        effect: 0,
        image_position: 0,
        typography: 0,
        design_id: 0,
    }
}

export function getEffectStyle(effect) {
    const style = {
        backgroundColor: 'transparent',
        backgroundImage: 'none',
    }

    switch(effect){
        case 'full':
            style.backgroundColor = "rgba(255, 255, 255, 0.5)";
            break;
        case 'bottom':
            style.backgroundImage = `linear-gradient(to bottom, rgba(255,255,255,0) 0 25%,  rgba(255,255,255, 0.75) )`;
            break;
        case 'top':
            style.backgroundImage = `linear-gradient(to top, rgba(255,255,255,0) 0 25%,  rgba(255,255,255, 0.75) )`;
            break;
        case 'center':
            style.backgroundImage = `linear-gradient(rgba(255,255,255,0) 10%, rgba(255,255,255, 0.75), rgba(255,255,255,0) 90%)`;
            break;
    }
    return style;
}

export function getPositionStyle(position) {
    return { justifyContent: position };
}