<template>
    <main class="designs" :class="{projectshare: store.getters.isProjectshare}">
        <TopBar v-if="!store.getters.isProjectshare"
                :title="translate('inspo.background_designs')"
                :backFunction="()=>navigate('inspirations')"
                :backLabel="translate('inspo.back_inspo')"
        >
            <template v-slot:topbar-end v-if="hasDesigns">
                <div class="hide-medium-flex">
                    <Search :value="searchString" @change="searchStringChange($event)" />
                    <DesignCategories :value="categoryId" @change="categoryIdChange($event)" />
                </div>
            </template>
        </TopBar>

        <section v-if="hasDesigns" class="search-cat-mobile"
                 :class="{ projectshare : store.getters.isProjectshare, 'show-medium-flex' : !store.getters.isProjectshare}"
        >
            <div class="card" >
                <Search :value="searchString" @change="searchStringChange($event)" />
                <DesignCategories :value="categoryId" @change="categoryIdChange($event)" />
            </div>
        </section>
        <div v-if="store.state.errorOccured">
            <ErrorOccured />
        </div>
        <section v-else class="designs-content">
            <section id="designs" class="designs-grid" :class="{ xsmall: store.state.xsmall, xbig: store.state.xbig }">
                <div v-for="design in shownDesigns" :key="design.code"
                     class="designs-images"
                     @click="openDesign(design)"
                >
                <div class="shadow-wrapper">
                    <img class="shadow-wrapper" :src="design.thumbnail" :alt="design.name">
                    <div class="preview-shadow"></div>
                </div>
                    <p>{{ design.code + ' - ' + design.name }}</p>
                </div>
            </section>
            <button class="primary-btn smaller"
                    v-if="!allFilteredShown"
                    @click="showMoreDesigns"
            >
                {{ translate('inspo.load_more') }}
            </button>
        </section>
    </main>
</template>

<script>

import {computed, onMounted, watch} from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router';
import { debounce } from 'lodash';
import { translate } from "@/libs/helper";
import { mapActions, mapGetters, mapMutations } from "@/libs/storeHelper";

import TopBar from '../components/TopBar.ce.vue';
import Search from '../components/Search.ce.vue';
import DesignCategories from '../components/DesignCategories.ce.vue';
import ErrorOccured from '../components/ErrorOccured.ce.vue';

export default {
    components: {
        TopBar,
        Search,
        DesignCategories,
        ErrorOccured
    },
    setup() {
        const store = useStore();
        const router = useRouter()
        const route = useRoute();

        const { hasDesigns, filteredDesigns } = mapGetters();
        const { setDesignState } = mapMutations();
        const { getDesigns } = mapActions();

        const pageSize = 42;

        const searchString = computed(()=>{
            return store.state.design.searchString;
        })
        const categoryId = computed(()=>{
            return store.state.design.categoryId;
        })

        const resultSize = computed(() => {
            return store.state.design.resultSize || pageSize;
        })

        const shownDesigns = computed(()=>{
            return filteredDesigns.value.slice(0, resultSize.value);
        });

        const allFilteredShown = computed(()=>{
           return resultSize.value >= filteredDesigns.value.length;
        });

        const showMoreDesigns = (() => {
            setDesignState({resultSize: resultSize.value + pageSize});
        });

        const openDesign = async (design) => {
            const query = {}
            if(searchString.value)
                query.search = searchString.value;
            else if(categoryId.value)
                query.categoryId = categoryId.value;
            return router.push({ name: 'design', params: { id: design.id }, query})
        }

        const navigate = async (component) => {
            return router.push({ name: component});
        }

        const searchStringChange = debounce((val) => {
            setDesignState({searchString:val});
        },200);

        const categoryIdChange = (val) => {
            setDesignState({categoryId:val});
        }

        const scrollToPosition = async () => {
            const element = document.getElementById("im4-inspiration-embed");
            if(!element)
                return;

            if(element.getBoundingClientRect().top + 50 > store.state.design.scrollY)
                return;

            if(store.state.design.scrollY) {
                setTimeout(() => {
                    window.scrollTo(0, store.state.design.scrollY)
                }, 10);
            }
        }

        const setScrollData = (scrollY) => {
            store.commit('setDesignState', { scrollY })
        }

        watch(searchString, (val) => {
            if(route.query.search == val)
                return;
            router.replace({query: val ? {search: val} : null})
        })
        watch(categoryId, (val) => {
            if(route.query.categoryId == val)
                return;
            router.push({query: {categoryId: val}})
        })

        onMounted(async ()=>{
            const querySearch = route.query.search || '';
            let queryCategory = route.query.categoryId || 'all';
            if(querySearch !== '')
                queryCategory = 'all';

            const setState = {}

            if(querySearch !== searchString.value)
                setState.searchString = querySearch;
            if(queryCategory !== categoryId.value)
                setState.categoryId = queryCategory;

            if(Object.keys(setState).length)
                setDesignState(setState);

            await getDesigns();
        });


        return {
            store,
            router,
            translate,
            hasDesigns,
            searchString,
            searchStringChange,
            categoryIdChange,
            categoryId,
            shownDesigns,
            allFilteredShown,
            showMoreDesigns,
            openDesign,
            navigate,
            scrollToPosition,
            setScrollData,
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if(from.name === 'design')
                vm.scrollToPosition()
         })
    },
    beforeRouteLeave(to, from, next) {
        if(to.name === 'design')
            this.setScrollData(window.top.scrollY)
        next()
    }
}
</script>

<style scoped>
    .card{
        width: 100%;
        padding: .3em;
    }

    .designs {
        width: 100%;
        min-height: 50vh;
    }

    .designs-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .designs-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-template-rows: repeat(auto, minmax(8em, 15vh));
        gap: 8px;
        margin: .2em 0 .6em 0;
    }

    .designs-grid.xsmall {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .designs-images {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
    }

    .designs-images img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        box-shadow: rgb(0 0 0 / 10%) 0px 3px 8px;
    }

    .designs-images p {
        margin: .5em 0 0 0;
        font-size: 1em;
        font-weight: 300;
        text-align: center;
    }


    .search-cat-mobile {
        margin: .2em 0 .5em;
        display: flex;
    }

    .projectshare.search-cat-mobile{
        margin: 0 0 .5em;
    }

    .mq-medium .designs-grid {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .mq-large .designs-topbar {
        display: flex;
        justify-content: space-between;
        background-color: var(--secondary);
        border-radius: 16px;
        margin: 0 8px;
        padding: 8px 16px;
    }

    .mq-large .designs-topbar-end {
        margin: 8px;
    }

    .mq-large .designs-grid {
        grid-template-columns: repeat(6, minmax(0, 1fr));
        margin: 16px 0;
    }

    .mq-large .designs-grid.xbig {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }

    .mq-large .search-cat-mobile:not(.projectshare) {
        display: none;
    }

    .mq-large .preview-shadow {
        padding: 1em 0;
    }

</style>
