<template>
    <main class="p-relative">
        <TopBar v-if="!store.getters.isProjectshare"
                :title="translate('inspo.inspiration')"
                :backFunction="()=>navigate('inspirations')"
                :backLabel="translate('inspo.back_inspo')"
        >
            <template v-slot:topbar-end>
                <Btn :clickFunction="toggleForm"
                     v-if="store.state.settings.allow_module_inquiries && !store.getters.isProjectshare"
                     :label="translate('inspo.contact_agency')"
                     :smallerText="store.state.size !== 'large'"
                     icon="share-from-square"
                     secondary
                />
                <Btn icon="search"
                     :label="translate('inspo.search_designs')"
                     :clickFunction="() => navigate('designs')"
                     :smallerText="store.state.size !== 'large'"
                />
            </template>
        </TopBar>

        <Preview :id="id" :items="inspirations" component="inspiration">
            <template v-slot:preview-image>
                <img v-if="inspiration.image_url"
                     class="image"
                     :src="inspiration.image_url"
                     :alt="inspiration.title"
                >
            </template>
            <template v-slot:info-text>
                <section class="inspiration-content">
                    <section class="info-section">
                        <p class="info-title"> {{ inspiration.title }}</p>
                        <section>
                            <section v-if="inspiration.content" class="info-section">
                                <p class="info-text" v-html="inspiration.content"></p>
                            </section>
                            <section v-if="inspiration.inspo_text" class="info-section">
                                <p class="info-text" v-html="inspiration.inspo_text"></p>
                            </section>
                        </section>
                    </section>

                </section>
                <section>
                    <section class="small-preview-images inspiration-images hide-medium">
                        <div v-for="item in sixNextItems" :key="item.id" @click="navPreview('inspiration', item.id)">
                            <img :src="item.cover_preview_url || item.image_url" :alt="item.name">
                        </div>
                    </section>
                </section>
            </template>
        </Preview>

        <ContactAgencyForm v-if="showForm"
                           :inspirationId="inspiration.id"
                           @hide="showForm=false"
        />
    </main>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { translate, scrollToTop } from "@/libs/helper";

import Preview from '../components/Preview.ce.vue';
import TopBar from '../components/TopBar.ce.vue';
import Btn from '../components/Btn.ce.vue';
import ContactAgencyForm from '../components/ContactAgencyForm.ce.vue';
import store from "../store/index";

export default {
    components: {
        Preview,
        TopBar,
        Btn,
        ContactAgencyForm
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const id = route.params.id;

        let showForm = ref(false)

        const inspirations = computed(() => {
            return store.state.inspiration.items
        })
        const inspiration = computed(() => {
            return inspirations.value.find(el => el.id == id) || {};
        })

        let findIndex = computed(() => {
            const findItemIndex = (element) => element.id == id;
            return inspirations.value?.findIndex(findItemIndex)
        })

        const sixNextItems = computed(() => {
            if(!inspirations.value)
                return [];

            let index = findIndex.value + 1
            let sliceEnd = index + 6

            let items =  inspirations.value.slice(index, sliceEnd)
            if(items.length < 6 && inspirations.value.length >= 6) {
                index = 0;
                sliceEnd = index + 6 - items.length;
                items = items.concat(inspirations.value.slice(index, sliceEnd));
            }
            return items;
        })

        const toggleForm = () => {
            showForm.value = !showForm.value
        }

        const navigate = (component) => {
            return router.push({ name: component});
        }

        const navPreview = (component, id) => {
            const query = setQuery()
            return router.push({ name: component, params: { id }, query})
        }

        const setQuery = () => {
            let query = {}
            if(route.query.search)  {
                query = {search: route.query.search}
            } else if(route.query.categoryId){
                query = {categoryId: route.query.categoryId}
            }
            return query
        }

        const getInspirations = (async () => {
            if(!inspirations.value.length)
                await store.dispatch('getInspirations');
        })

        return {
            store,
            id,
            inspiration,
            inspirations,
            navigate,
            translate,
            getInspirations,
            toggleForm,
            showForm,
            sixNextItems,
            navPreview
        }
    },
    beforeRouteEnter(to, from, next) {
        if(Object.keys(store.state.settings).length === 0)
            store.dispatch('getSettings');

        scrollToTop()

        next(vm => {
            checkRoute(vm, vm.id)
        })
    },
    async beforeRouteUpdate(to, from, next) {
         await checkRoute(this, to.params?.id)
         next(scrollToTop())
    },
}
async function checkRoute(ctx, id) {
    await ctx.getInspirations()
    const find = await ctx.store.state.inspiration?.items.find(el => el.id == id)
    
    if(!find)
        ctx.navigate('inspirations')
    
    return find
}

</script>

<style scoped>
    .image {
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
        height: 100%;
    }

    .small-preview-images img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }

    .small-preview-images {
        display: grid;
        grid-template-rows: repeat(3, minmax(2em, 5em));
        grid-template-columns: repeat(2, minmax(6em, 1fr));
        grid-gap: 1em;
        width: 100%;
        padding: 0 0 .5em 0;
    }

    .inspiration-content {
        white-space: normal;
    }
</style>