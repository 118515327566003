import store from '../store/index'

export const translate = (key) => {
    return store.getters["translate"](key);
};

export const is_true = function(val) {
    switch (typeof val) {
        case "number":
            return val !== 0;

        case "string":
            return val !== "false" && val !== "0" && val !== "";

        default:
            return !!val;
    }
}

export const nl2br = (str) => {
    if (!str)
        return null;
    return str.replace(/\r\n|\r|\n/g, '<br>');
}

export const updateRouteQuery = (route, router, update) => {
    router.replace({ query: {...route.query, ...update}});
}

export function searchItems(q, items) {
    const query = q.trim().toLowerCase().split(' ');
    let added = [];

    return items.filter(item => {
        if(added.includes(item.id))
            return false;
        if(item.hidden)
            return false;

        let searchIn = item.searchIn;
        if(!searchIn) {
            Object.keys(item).forEach(key => {
                if(key!=='id' && item[key])
                    searchIn += (item[key]+";").toLowerCase()
            });
        }

        if (!query.every(q => searchIn.includes(q)))
            return false;

        added.push(item.id);
        return true;
    });
}

export function scrollToTop() {
    const element = document.getElementById("im4-inspiration-embed");
    if(!element)
        return;

    let topOffset = 0;
    const headers = document.getElementsByTagName('header');
    if(headers.length === 1) {
        const header = headers[0];
        if(window.getComputedStyle(header).position === 'fixed')
            topOffset = - (header.getBoundingClientRect().height + 15)
    }

    const screenPosition = element.getBoundingClientRect();
    topOffset += screenPosition.top;

    if(Math.abs(topOffset) < 150)
        return;

    setTimeout(() => {
        window.scrollTo({top:topOffset + window.pageYOffset, behavior:'smooth'});
    }, 10);
}