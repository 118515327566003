import { createWebHashHistory, createRouter } from 'vue-router'
import Design from '../pages/Design.ce.vue'
import Designs from '../pages/Designs.ce.vue'
import Inspiration from '../pages/Inspiration.ce.vue'
import Inspirations from '../pages/Inspirations.ce.vue'
import store from "../store/index"

const routes = [
    {
        path: '/',
        name: 'inspirations',
        component: Inspirations
    },
    {
        path: '/inspiration/:id',
        name: 'inspiration',
        component: Inspiration
    },
    {
        path: '/design/:id',
        name: 'design',
        component: Design,
        beforeEnter: (to, from, next) => {
            const sessionKey = sessionStorage.getItem("inspo_key");

            if(sessionKey && (!to.query.key || to.query.key !== sessionKey)) {
                store.commit('setInquiryKey', sessionKey);
                to.query.key = sessionKey;
                next({ replace: true, name: "design", params: to.params, query: to.query })
            } else {
                if(to.query.key)
                    sessionStorage.setItem("inspo_key", to.query.key);
                next();
            }
        },
    },
    {
        path: '/designs',
        name: 'designs',
        component: Designs
    },
    {
        path: '/share',
        name: 'share',
        component: Designs,
        beforeEnter: (to, from, next) => {
            if(to.query.key) {
                store.dispatch("getInquiry", to.query.key).then(() => {
                    next({ replace: true, name: "design", params: { id: store.state.inquiry.inquiry.json.design_id }, query: to.query })
                })
            }
        }
    },
    {
        path: '/:pathMatch(.*)*',
        component: Inspiration
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.afterEach(() => {
    dispatchEvent(new CustomEvent('insporoute', {
        bubbles: true,
        composed: true,
        detail: window.location.hash
    }))
});

export default router
