<template>
    <Modal :busy="busy">
        <section class="w-100 content" :class="store.getters.contextClassNames">
            <p v-html="translate('inspo.message_sent')" v-if="sent"></p>
            <section class="form-wrapper" v-else>
                <div class="form-section">
                    <div class="form-section-child">
                        <label for="name">{{translate("inspo.full_name_req")}}</label>
                        <input required class="input-light" type="text" :value="store.getters.getName" @change="store.commit('setName', $event.target.value)">
                    </div>

                    <div class="form-section-child">
                        <label for="email">{{translate("inspo.email_req")}}</label>
                        <input required class="input-light" :value="store.getters.getEmail" @change="store.commit('setEmail', $event.target.value)">
                    </div>

                    <div class="form-section-child">
                        <label for="deceased">{{translate("inspo.name_of_deceased")}}</label>
                        <input class="input-light" :value="store.getters.getDeceasedName" @input="store.commit('setDeceasedName', $event.target.value)" >
                    </div>
                </div>
                <div class="form-section">
                    <div class="form-section-child">
                        <label for="formMessage">{{translate("inspo.message")}}</label>
                        <textarea class="input-light"  :value="store.getters.getMessage" @change="store.commit('setMessage', $event.target.value)" />
                    </div>
                </div>
            </section>
        </section>

        <template v-slot:buttons >
            <Btn :clickFunction="()=>$emit('hide')" :label="sent ? translate('inspo.close') : translate('inspo.cancel')" secondary />
            <Btn :clickFunction="sendInquiry" :disabled="!canSubmit" :label="translate('inspo.send')" v-if="!sent" />
        </template>
    </Modal>
</template>

<script>
import { translate } from "@/libs/helper";

import Btn from '../components/Btn.ce.vue';
import Modal from '../components/Modal.ce.vue';
import {useStore} from "vuex";
import {computed, ref} from "vue";

export default {
    components: {
        Btn,
        Modal,
    },
    props: {
        designId: Number,
        inspirationId: Number,
    },
    emits:['hide'],
    setup(props) {
        const store = useStore();

        const busy = ref(false);
        const sent = ref(false);

        const canSubmit = computed(()=>{
           return store.getters.getName && store.getters.getEmail;
        });

        const sendInquiry = async ()=>{
            busy.value = true;

            await store.dispatch('submitInquiry', {
                designId: props.designId,
                inspirationId: props.inspirationId,
            });

            busy.value = false;
            sent.value = true;
        }

        return {
            store,
            translate,
            busy,
            sent,
            sendInquiry,
            canSubmit,
        }
    }
}
</script>

<style scoped>

input, textarea {
    width: 100%;
}

.form-wrapper{
    margin: 1em .5em 1em .5em;
    display:flex;
    flex-direction: column;
    gap: 1em;
}

.form-section {
    display:flex;
    flex-direction: column;
    gap: .5em;
    width: 100%;
}

.form-section-child {
    width: 100%;
    margin-bottom: .52em;
    display: flex;
    flex-direction: column;
    gap: .5em;
}

.mq-large .form-wrapper {
    flex-direction: row;
}
.mq-large textarea{
    height: 218px;
}

</style>
