<template>
    <section> 
        <section v-if="selectedFont" class="selected-font" @click="toggleFonts">
            <img :src="selectedFont.preview" :alt="selectedFont.name">
            <font-awesome-icon class="icon" color="black" height=".5em" :icon="['far', 'chevron-down']"/>
        </section>
        <section class="select-font" v-if="showFonts">
            <div v-for="font in fonts" :key="font.id" @click="setCustomFont(font)">
                <img :src="font.preview" :alt="font.name">
            </div>
        </section>
    </section>
</template>

<script>
import { computed, ref } from 'vue';
import { translate } from "@/libs/helper";

export default {
    props: {
        fonts: Array,
        createFontFace: Function,
        cachedFont: [Object, String]
    },
    emits:['fontChange'],
    setup(props, {emit}) {
        const showFonts = ref(false)

        const selectedFont = computed(() => {
            if(!props.cachedFont) {
                return props.fonts?.find(font => font.default)
            } else {
               return props.cachedFont
            }
        })

        const toggleFonts = () => {
            showFonts.value = !showFonts.value
        }

        const setCustomFont = (font) => {
            emit('fontChange', {data: font, element: props.fontTarget})
            toggleFonts()
        }

        return {
            translate,
            selectedFont,
            toggleFonts,
            showFonts,
            setCustomFont
        }
    }
}
</script>

<style scoped>
    .selected-font {
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        max-width: 100%;
        background-color: white;
        border: 1px solid var(--gray-300);
        border-radius: .5em;
        padding: .25em .5em;
        align-items: center;
        justify-items: center;
        cursor: pointer;
    }

    .selected-font img {
        grid-column: 1/10;
        justify-self: start;
        image-rendering: -webkit-optimize-contrast;
    }

    .select-font {
        padding-top: .2em;
    }

    .select-font img {
        margin-bottom: -0.3em;
        padding: .25em .5em;
        background-color: white;
        border-radius: .5em;
        image-rendering: -webkit-optimize-contrast;
        border: 1px solid var(--gray-300);
        cursor: pointer;
    }

    /* Targets only Safari 10+ */
    @media not all and (min-resolution:.001dpcm) { 
        .selected-font img,
        .select-font img {
            image-rendering: auto;
        }
    }

</style>
