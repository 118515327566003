<template>
    <Modal :busy="busy">
        <section class="w-100 content">
            <p v-html="shareDesignMsg"></p>

            <div class="input-light">
                <a id="design-link" :href="shareUrl" target="_blank">
                    {{ shareUrl }}
                </a>
                <button @click="()=>copyUrl()" class="copy">
                    <font-awesome-icon class="icon" color="black" height="1em" :icon="['far', 'copy']" />
                </button>
            </div>

            <p class="smaller-text" v-if="linkCopied">{{ translate("inspo.link_copied") }}</p>
        </section>
        <template v-slot:buttons >
            <Btn :disabled="busy" :clickFunction="()=>$emit('hide')" :label="translate('inspo.close')" secondary />
        </template>
    </Modal>
</template>

<script>
import { translate } from "@/libs/helper";

import Btn from '../components/Btn.ce.vue';
import Modal from '../components/Modal.ce.vue';
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";

export default {
    components: {
        Btn,
        Modal,
    },
    props: {
        designId: Number,
    },
    emits:['hide'],
    setup(props) {
        const store = useStore();

        const busy = ref(false);
        const updatedLink = ref(false);
        const linkCopied = ref(false);

        const shareDesignMsg = computed(() => {
            let message = updatedLink.value
                ? translate("inspo.saved_design_message")
                : translate("inspo.share_design_message");
            return message.replace(/\r\n|\r|\n/g, "<br>");
        });

        const shareUrl = computed(() => {
            return window.location.origin + window.location.pathname + "#/share?key=" + store.state.inquiry.inquiryKey;
        });

        const copyUrl = (auto = false)=> {
            if(!auto)
                linkCopied.value = true;

            let temp = document.createElement("textarea");
            document.body.appendChild(temp);

            temp.value = shareUrl.value;
            temp.select();

            document.execCommand("copy");
            document.body.removeChild(temp);
        }

        onMounted(async ()=>{
            busy.value = true;

            const before = store.state.inquiry.inquiryKey;

            await store.dispatch('submitInquiry', {
                shareOnly: true,
                designId: props.designId
            });
            updatedLink.value = before === store.state.inquiry.inquiryKey;

            busy.value = false;
            copyUrl(true);
        });

        return {
            translate,
            busy,
            shareDesignMsg,
            shareUrl,
            copyUrl,
            linkCopied,
        }
    }
}
</script>

<style scoped>

.content {
    padding: 0 .8em;
    margin-bottom: .8em;
}

.input-light{
    position: relative;
    text-align: center;
    padding-right: 2.4em;
}

#design-link {
    font-size: 70%;
    text-decoration: none;
}
.copy{
    position: absolute;
    right: 4px;
    top:11px;
}

</style>
