<template>
    <div class="error-occured">
        <h3>{{ translate('inspo.error_occured') }}</h3>
        <button class="primary-btn" @click="refreshPage">{{ translate('inspo.refresh') }}</Button>
    </div>
</template>

<script>
import { translate } from "@/libs/helper";

export default {
    setup() {
        const refreshPage = () => {
            location.reload();
        }

        return {
            translate,
            refreshPage
        }
    }
}
</script>

<style scoped>
    .error-occured {
        padding-top: 2em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>
