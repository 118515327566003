<template>
    <div class="button" :class="{secondaryText}">
        <font-awesome-icon class="icon" v-if="backNavigation" height="12" :icon="['far', icon]" />
        <button class="label"
                :class="{ textAfter: backNavigation }"
                @click="clickFunction"
        >
            {{ label }}
        </button>
        <font-awesome-icon class="icon" v-if="!backNavigation" height="12" :icon="['far', icon]" />
    </div>
</template>

<script>
import { translate } from "@/libs/helper";

export default {
    props: {
        clickFunction: [String, Function],
        icon: String,
        label: String,
        backNavigation: Boolean,
        secondaryText: Boolean,
    },
    setup() {
        return {
            translate
        }
    }
}
</script>

<style scoped>
    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 .5em 0 0;
    }
    .label {
        font-size: .75em;
        padding: 0 .75em 0 0;
    }
    .label.textAfter{
        padding: 0 0 0 .75em;
    }

    .secondaryText .label,
    .secondaryText .icon
    {
        color: var(--secondary-text);
    }
</style>
