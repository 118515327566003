<template>
    <section class="preview" :class="previewClass">
        <section class="preview-image-wrapper" :class="{isDesign}">
            <section class="preview-image" :class="{isDesign}">
                <slot name="preview-image">
                    <font-awesome-icon height="1em" color="black" class="fa-spin" :icon="['far', 'spinner']"/>
                </slot>
                <div v-if="isDesign" class="preview-shadow"></div>
            </section>
        </section>
        <section class="preview-nav show-small-flex" v-if="showNavigation && !store.state.edit_disabled">
            <BtnNavigation icon="chevron-left"
                           :label="translate('inspo.previous')"
                           :clickFunction="navigateBack"
                           backNavigation
            />
            <BtnNavigation icon="chevron-right"
                           :label="translate('inspo.next')"
                           :clickFunction="navigateNext"
            />
        </section>
        <section class="preview-content" :class="{ isDesign }">
            <section class="info-text-section">
                <slot name="info-text"></slot>
            </section>
        </section>
    </section>
    <section class="preview-nav hide-small-flex" v-if="showNavigation && !store.state.edit_disabled">
        <BtnNavigation icon="chevron-left"
                       :label="translate('inspo.previous')"
                       :clickFunction="navigateBack"
                       backNavigation
        />
        <BtnNavigation icon="chevron-right"
                       :label="translate('inspo.next')"
                       :clickFunction="navigateNext"
        />
    </section>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted, onBeforeUnmount } from 'vue'
import { translate } from "@/libs/helper";

import BtnNavigation from '../components/BtnNavigation.ce.vue';

export default {
    components: { BtnNavigation },
    props: {
        id: String,
        component: String,
        items: Array,
        search: String,
        onBackground: Boolean,
    },
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();

        const isDesign = computed(()=>{
            return props.component === 'design';
        })

        const itemIndex = computed(() => {
            return props.items?.findIndex((element) => element.id == props.id)
        })

        const showNavigation = computed(()=>{
           return props.items?.length>1;
        });

        const previewClass = computed(()=>{
            let classNames = store.getters.contextClassNames;

            if(props.onBackground)
                classNames += " on-background";

            return classNames;
        });

        const navigate = (id) => {
            let query = {}
            if(route.query.search)  {
                query = {search: route.query.search}
            } else if(route.query.categoryId){
                query = {categoryId: route.query.categoryId}
            }

            const sessionKey = sessionStorage.getItem("inspo_key");
            if(sessionKey)
                query.key = sessionKey;

            if(store.state.design.editMode)
                query.editMode = true;
            if(store.state.design.showFeed)
                query.showFeed = true;
            return router.push({ name: props.component, params: { id }, query })
        }

        const navigateNext = () => {
            const nextIndex = itemIndex.value + 1
            let nextElement = props.items[nextIndex]
            if(itemIndex.value === props.items.length - 1)
                nextElement = props.items[0]
            navBackForth(nextElement)
        }

        const navigateBack = () => {
            let prevIndex = itemIndex.value - 1
            let nextElement = props.items[prevIndex]
            if(itemIndex.value === 0)
                nextElement = props.items[props.items.length - 1]
            navBackForth(nextElement)
        }

        const navBackForth = (nextElement) => {
            if(!nextElement)
                return;

            if(isDesign.value)
                return navigate(nextElement.id)
            router.push({ name: props.component, params: { id: nextElement.id }})
        }

        const keyDown = (e) => {
            if(!props.items?.length)
                return;

            const activeElement = document.getElementById('im4-inspiration-embed').shadowRoot.activeElement;
            if(activeElement?.tagName.toLowerCase() == "input" || activeElement?.tagName.toLowerCase() == "textarea")
                return
            if(e?.keyCode === 39) // Next
                navigateNext()
            else if(e?.keyCode === 37)  // Previous
                navigateBack()
        }

        onMounted(()=>{
            window.addEventListener('keydown', keyDown);
            keyDown();
        });

        onBeforeUnmount(()=>{
            window.removeEventListener('keydown', keyDown);
        });

        return { 
            store,
            translate,
            navigate,
            navigateNext,
            navigateBack,
            showNavigation,
            isDesign,
            previewClass,
        }
    }
}
</script>

<style scoped>

.preview {
    display: flex;
    flex-direction: column;
    margin: 16px 0 8px;
    user-select: none;
}

.projectshare.preview {
    margin: 0 0 8px;
}

.preview-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.preview-image-wrapper.isDesign {
    margin-bottom: .5em;
}
.mq-small .preview-image-wrapper {
    margin-bottom: .5em;
}

.preview-image:not(.isDesign) {
    height: 100%;
}

.preview.on-background .preview-image-wrapper {
    padding: 5px;
    background-color: var(--secondary);
}

.preview-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    max-height: 80vw;
    width: 100%;
}
.preview-image:not(.isDesign) {
    box-shadow: rgb(0 0 0 / 10%) 0 3px 8px;
}

.preview-image,
.preview-content,
.preview-image-wrapper{
    border-radius: 8px;
}

.preview-content .info-text-section {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    padding: 1em 1em 0.5em 1em;
}

.preview-content:not(.isDesign) .info-text-section {
    overflow-x: hidden;
    overflow-y: scroll;
    grid-gap: 0.75em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.preview-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: .25em;
    user-select: text;
    background-color: var(--secondary);
    text-align: center;
    margin: 0;
}

.preview-nav {
    display: flex;
    justify-content: space-between;
    user-select: none;
    margin-bottom: .5em;
}

.mq-medium .preview,
.mq-large .preview {
    flex-direction: row;
}

.mq-medium .preview {
    gap: .5em;
    margin: 8px 0;
}
.mq-large .preview {
    gap: 1em;
}

.mq-medium .preview-image,
.mq-large .preview-image {
    max-height: 100%;
    margin: 0;
    padding: 0;
    box-shadow: rgb(0 0 0 / 10%) 0 3px 8px;
}

.mq-medium .preview-content,
.mq-large .preview-content {
    margin: 0;
    max-width: 100%;
}

.mq-medium .preview-content:not(.isDesign) .info-text-section {
    margin: 0;
}

.mq-medium .preview-image {
    flex-grow: 1;
}

.mq-medium .preview-content{
    width: 240px;
    min-width: 240px;
}

.mq-large .preview-image {
    flex-grow: 1;
}

.mq-large .preview-content{
    width: 32%;
    min-width: 260px;
    max-width: 450px;
}

</style>