<template>
    <section class="modal-wrapper" :class="modalClass">
        <section class="modal">
            <div class="modal-content">
                <div class="modal-inner-content" >
                    <section v-if="busy" class="spin-wrapper">
                        <font-awesome-icon class="fa-spin" height="1em" color="black"  :icon="['far', 'spinner']"/>
                    </section>
                    <slot v-else />
                </div>
                <section v-if="$slots.buttons" class="modal-content-buttons">
                    <slot name="buttons" />
                </section>
            </div>
        </section>
    </section>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
    props: {
        busy: Boolean,
    },
    setup() {
        const store = useStore();

        const modalClass = computed(()=>{
            // possible to add different sizes to modals
            return store.getters.contextClassNames;
        });

        return {
            modalClass,
        }
    }
}
</script>

<style scoped>

.modal-wrapper {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0,0.5);
    z-index: 100;
}

.modal {
    background-color: var(--gray-200);
    border-radius: 1em;
    min-width: 80%;
}

.modal-content {
    padding: 1em;
}

.modal-inner-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 120px;
}

.modal-content-buttons{
    display: flex;
    justify-content: center;
    gap: .25em;
    text-align: center;
    flex-grow: 0;
}

.mq-large .modal{
    min-width: 40%;
}


</style>