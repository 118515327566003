export function fetchData(ctx, url) {
    return new Promise((resolve, reject) => {
        return fetch(url)
            .then(async r => {
                if(r.status == 200) {
                    r.json()
                    .then(async r => {
                        resolve(r);
                    });
                } else {
                    await ctx.commit("setErrorOccured", true, {root: true})
                    reject(r)
                }
            })
    })
}

export async function postData(url, data) {
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });

  return response.json(); // parses JSON response into native JavaScript objects
}