<template>
    <section class="design-buttons-wrapper">
        <Btn :clickFunction="()=>$emit('shareDesign')"
             :icon="shareButton.icon"
             :label="shareButton.text"
             secondary
             :roundOnSmall="store.state.xsmall"
             :smallerText="store.state.size !== 'large'"
        />
        <Btn v-if="store.state.settings.allow_module_inquiries"
             :clickFunction="()=>$emit('toggleForm')"
             icon="envelope"
             :label="translate('inspo.contact_agency')"
             secondary
             :roundOnSmall="store.state.xsmall"
             :smallerText="store.state.size !== 'large'"
        />
        <Btn v-if="!store.state.edit_disabled"
             :clickFunction="()=>$emit('toggleEdit')"
             :icon="editBtn.icon"
             :label="editBtn.text"
             :smallerText="store.state.size !== 'large'"
             :roundOnSmall="store.state.xsmall"
        />
    </section>
</template>

<script>
import { useStore } from 'vuex'
import { translate } from "@/libs/helper";

import Btn from '../components/Btn.ce.vue';

export default {
    components: { Btn },
    props: {
        editBtn: Object,
        shareButton: Object,
    },
    setup() {
        const store = useStore();

        return {
            translate,
            store,
        }
    }
}
</script>

<style scoped>

.design-buttons-wrapper {
    gap: .25em;
    margin-left: .25em;
}

</style>
