import { fetchData } from '@/libs/apiHelper'

const inspiration = {
    modules: {
        namespaced: true
    },
    state: {
        items: [],
        selectedIndex: 1,
    },
    mutations: {
        setInspirations(state, inspirations) {
            state.items = inspirations;
        },
        setSelectedIndex(state, selectedIndex) {
            state.selectedIndex = selectedIndex;
        },
    },
    actions: {
        getInspirations(ctx){
            const url = `${ctx.rootState.url}/inspiration/${ctx.rootState.agency_key}/all?shuffle=true`;
            return fetchData(ctx, url)
                .then(r => {
                    ctx.commit("setInspirations", r)
                })
                .catch(() => ctx.commit("setInspirations", []));
        }
    },
}

export default inspiration